import 'swiper/css';
import React, {useEffect, useState} from "react";
import Header from "./Header";
import PhoneCall from '../images/bxs_phone-call.svg';
import EmailImg from '../images/ic_sharp-email.svg';
import LocationImg from '../images/carbon_location-filled.svg';
import $ from "jquery";
import Cursor from "./cursor";
import Footer from "./Footer";
import emailjs from "@emailjs/browser";

function ContactUs(){
    const [isDesktop, setIsDesktop] = useState(window.innerWidth < 639);

    // Production
    const emailJsPublickKey = "faFJYzubP-KTGb2J_";
    const serviceId = "service_fue2z9o";
    const templateId = "template_fsackgg";

    // Testing
    // const emailJsPublickKey = "MRdOE9WjWEdShtrk1";
    // const serviceId = "service_gx6dqv5";
    // const templateId = "template_1lan11q";


    $(document).ready(function() {
          $('.ElementSelected').attr('class', '');
          $('.RadioButtons input').click(function() {
                $('.RadioButtons input').prop('checked', false);
                $(this).prop('checked', true);
          });
    });

    const [subject, setSubject] = useState("General Inquiry");

    const radioButtonOnClick = (e, data) => {
        setSubject(data);
    };



     useEffect(() => emailjs.init(emailJsPublickKey), []);

     const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {subject: subject, reply_to: 'dnsonlinecenter@gmail.com'};

        formData.forEach((value, key) => {
          data[key] = value;
        });

        var inputs = document.querySelectorAll('.ContactUsMainInput input');
        inputs.forEach(function(input) {
            input.value = '';
        });

        try {
          // setLoading(true);
          await emailjs.send(serviceId, templateId, data);
          alert("email sent successfully");
        } catch (error) {
          console.log(error);
        } finally {
          // setLoading(false);
        }
     };

     useEffect(() => {
        if (window.innerWidth < 639){
            setIsDesktop(false);
        }
        else {
            setIsDesktop(true);
        }
    }, []);

  return (
      <div>
          <div className="humberger_grad active"></div>

      <div className="ContactUs" style={{transition: '.6s'}}>

          <Header />
          <div>
              <div className="ContactUsdiv">
                  <span className="span-3 ContactUsSpan">Contact Us</span>
              </div>


              <div className="ContactUsdiv2">
                  Any question or remarks? Just write us a message!
              </div>

              <div className="ContactUsMain">
                <div className="LinearGradientBox">

                    <div className="contactInfo">
                        Contact Information
                    </div>

                    <div className="contactInfo2">
                        Say something to start a live chat!
                    </div>

                    <div className="ContactUsInfo5">
                        <div className="ContactUsInfo4">
                            <img src={PhoneCall} alt=""/>
                            <div className="ContactUsInfo3 ContactUsInfo6">
                                +82 10 6888 8170
                            </div>
                        </div>

                        <div className="ContactUsInfo4">
                            <img src={EmailImg} alt=""/>
                            <div className="ContactUsInfo3 ContactUsInfo7">
                                logscrap.kr@gmail.com
                            </div>
                        </div>

                        <div className="ContactUsInfo4">
                            <img className="ContactUsInfoImg" src={LocationImg} alt=""/>
                            <div className="ContactUsInfo3 ContactUsInfo8">
                                26Floor, Convensia-daero 165, Yeonsu-gu, Incheon, Republic of Korea <br/> 인천광역시 연수구 컨벤시아대로 165, 26층
                            </div>
                        </div>

                    </div>

                    <div className="LinearGradientRound1"/>
                    <div className="LinearGradientBox2">
                        <div className="LinearGradientBox3" />
                    </div>

                    <div className="LinearGradientRound2" />
                </div>

                  <form onSubmit={handleSubmit} className="DesktopContactUsFrom">
                      <div className="ContactUsMainInput">
                          <div className="ContactInputDiv">
                              <div className="ContactInput">
                                <div className="ContactUsText">First Name</div>
                                <input className="ContactUsTextInput" type="text" name="first_name" required tabindex="1"/>
                                <div className="ContactUsText">Email</div>
                                <input className="ContactUsTextInput" type="email" name="email" required tabindex="3"/>
                              </div>

                              <div className="ContactInput">
                                    <div className="ContactUsText">Last Name</div>
                                    <input className="ContactUsTextInput" type="text" name="last_name" required tabindex="2"/>
                                    <div className="ContactUsText">Phone Number</div>
                                    <input className="ContactUsTextInput"  name="phone_number" required tabindex="4"/>
                              </div>
                          </div>

                          <div className="ContactSubjectDiv">
                              <div className="ContactSubjectDivTxt">Select Subject?</div>
                              <div className="RadioButtons" style={{display: "flex", marginTop: "2rem", gap: '2.5rem'}}>
                                  <div className="TickCircle">
                                      <input type="radio" className="checkbox-round-new" onClick={() => radioButtonOnClick("General Inquiry")} defaultChecked/>
                                      <div style={{marginLeft:'0.8rem'}}>General Inquiry</div>
                                  </div>

                                  <div className="TickCircle">
                                      <input type="radio" className="checkbox-round-new" onClick={() => radioButtonOnClick("Project Inquiry")} />
                                      <div style={{marginLeft:'0.8rem'}} >Project Inquiry</div>
                                  </div>
                              </div>

                              <div style={{marginTop:'3.5rem'}}>Message</div>
                              <input type="text" placeholder="Write your message.." name="message" className="ContactUsTextInput ContactUsTextInput2" />
                          </div>

                          <button className="ContactUsFormButton bttn bttn-primary" type="submit">
                              Send Message
                          </button>
                      </div>
                  </form>


                  <form onSubmit={handleSubmit} className="MobileContentUsForm">
                      <div className="ContactUsMainInput">
                          <div className="ContactInputDivMobile">
                              <div className="ContactInput">
                                <div className="ContactUsText">First Name</div>
                                <input className="ContactUsTextInput" type="text" name="first_name" required tabindex="1"/>
                                <div className="ContactUsText">Last Name</div>
                                  <input className="ContactUsTextInput" type="text" name="last_name" required tabindex="2"/>
                              </div>

                              <div className="ContactInput">
                                  <div className="ContactUsText" >Email</div>
                                <input className="ContactUsTextInput" type="email" name="email" required tabindex="3"/>
                                    <div className="ContactUsText">Phone Number</div>
                                    <input className="ContactUsTextInput" type="text" name="phone_number" required tabindex="4"/>
                              </div>
                          </div>

                          <div className="ContactSubjectDiv">
                              <div className="ContactSubjectDivTxt">Select Subject?</div>
                              <div className="RadioButtons" style={{display: "flex", marginTop: "2rem", gap: '2.5rem'}}>
                                  <div className="TickCircle">
                                      <input type="radio" className="checkbox-round-new" onClick={() => radioButtonOnClick("General Inquiry")} defaultChecked/>
                                      <div style={{marginLeft:'0.8rem'}}>General Inquiry</div>
                                  </div>

                                  <div className="TickCircle">
                                      <input type="radio" className="checkbox-round-new" onClick={() => radioButtonOnClick("Project Inquiry")} />
                                      <div style={{marginLeft:'0.8rem'}} >Project Inquiry</div>
                                  </div>
                              </div>

                              <div style={{marginTop:'3.5rem'}}>Message</div>
                              <input type="text" placeholder="Write your message.." name="message" className="ContactUsTextInput ContactUsTextInput2" required/>
                          </div>

                          <button className="ContactUsFormButton" type="submit">
                              Send Message
                          </button>
                      </div>
                  </form>

              </div>


          </div>
          {isDesktop && (<Cursor/>)}
      </div>
          <Footer/>
      </div>
  );
}

export default ContactUs;