import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import React, {useEffect, useRef, useState} from "react";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper/modules";
import Sangmin from "../images/Sangmin.png";
import ArrowBack from "../images/left purple.svg";
import ArrowForword from "../images/ArrowForward.svg";
import LeftWhiteArrow from "../images/Left white.svg";
import RightPurpleArrow from "../images/Right Purple.svg";
import ArrowNext from "../mobile_images/arrow-next.svg";

function Experts(){
    const swiperRef = useRef(null);

    const [nextExportsClass, setExportsNextClass] = useState('ExportsEllipseRight');
    const [prevExportsClass, setExportsPrevClass] = useState('ExportsEllipseRight ExportsEllipseLeft');

    const [arrowNext, setArrowNext] = useState(ArrowBack);
    const [arrowPrev, setArrowPrev] = useState(ArrowForword);

    const [slidesPerView, setSlidesPerView] = useState(0);

    useEffect(() => {
        if (navigator.maxTouchPoints > 0 ){
            setSlidesPerView(1);
        }
        else {
            setSlidesPerView(3);
        }
    },[]);


   const goToExportsPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();

      setExportsNextClass('ExportsEllipseRight ExportsEllipseLeft');
      setExportsPrevClass('ExportsEllipseRight');

      setArrowNext(LeftWhiteArrow);
      setArrowPrev(RightPurpleArrow);

    }
  };

  const goToExportsNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();

      setExportsNextClass('ExportsEllipseRight');
      setExportsPrevClass('ExportsEllipseRight ExportsEllipseLeft');

      setArrowNext(ArrowBack);
      setArrowPrev(ArrowForword);

    }
  };


  return (
      <div className="OurExports">
            <div className="EllipseClass">
                <div className="Ellipse"></div>
            </div>

            <div className="OurExportsDiv">
                <div className="LineGradientSubDiv1"></div>
            </div>

            <div className="OurExportsDiv OurExportsDiv3">
                <div className="OurExportsText">
                        <span className="OurExportsSpan1" >Our </span>
                        <span className="OurExportsSpan2Mobile" >Our </span>
                        <span className="OurExportsSpan2">Experts </span>
                </div>
                <button className="OurExportsDiv3button" onClick={goToExportsNextSlide}>
                    <img className="MobileArrowNext" src={ArrowNext} alt="arrow-next"/>
                </button>
            </div>

            <div className="OurExportsDiv2">

                <Swiper
                      // install Swiper modules
                      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={0}
                      slidesPerView={slidesPerView}
                      navigation={true}
                      ref={swiperRef}
                      pagination={{ clickable: true }}
                      scrollbar={{ draggable: true }}
                      loop={true}  // Enable looping
                      speed={1000}
                      autoplay={{delay: 2000, disableOnInteraction: false,}}
                      // onSwiper={(swiper) => console.log(swiper)}
                      // onSlideChange={() => console.log('slide change')}
                    >

                        <SwiperSlide key={0}>
                            <div className="ExportsImgDivMargin">
                                <div className="ExportsImgDiv">
                                    <img className="ExportsImg" src={Sangmin} alt="" />
                                </div>
                            </div>

                            <div className="ExportsName">Sangmin Lee</div>
                            <div className="ExportsRole">CEO</div>

                        </SwiperSlide>



                        <SwiperSlide key={1}>
                            <div className="ExportsImgDivMargin">
                                <div className="ExportsImgDiv">
                                    <img className="ExportsImg" src={Sangmin} alt="" />
                                </div>
                            </div>
                            <div className="ExportsName">Vishal Chandwani</div>
                            <div className="ExportsRole">LEAD UX Designer</div>
                        </SwiperSlide>

                        <SwiperSlide key={2}>
                            <div className="ExportsImgDivMargin">
                                <div className="ExportsImgDiv">
                                    <img className="ExportsImg" src={Sangmin} alt=""  />
                                </div>
                            </div>
                            <div className="ExportsName">Dayna Chandera</div>
                            <div className="ExportsRole">UNITY DEVELOPER</div>
                        </SwiperSlide>

                        <SwiperSlide key={3}>
                            <div className="ExportsImgDivMargin">
                                <div className="ExportsImgDiv">
                                    <img className="ExportsImg" src={Sangmin} alt="" />
                                </div>
                            </div>
                             <div className="ExportsName">Rajat Gupta</div>
                             <div className="ExportsRole">Graphic Designer</div>
                        </SwiperSlide>

                         <SwiperSlide key={4}>
                             <div className="ExportsImgDivMargin">
                                <div className="ExportsImgDiv">
                                    <img className="ExportsImg" src={Sangmin} alt=""  />
                                </div>
                             </div>
                             <div className="ExportsName">Nagaraj Kalakutagi</div>
                             <div className="ExportsRole">Fullstack Developer</div>
                        </SwiperSlide>

                </Swiper>
            </div>



              <div className="OurExportsDiv OurExportsArrow">
                <button onClick={goToExportsPrevSlide} className={nextExportsClass}>
                    <div>
                        <img className="ArrowBack" src={arrowNext} alt="" />
                    </div>
                </button>
                <button onClick={goToExportsNextSlide} className={prevExportsClass}>
                    <div>
                        <img className="ArrowBack" src={arrowPrev} alt="" />
                    </div>
                </button>
              </div>
      </div>
  );

}

export default Experts;