// import logo from '../images/bpm_links_logo 1 (2).svg';
import logo from '../mobile_images/Logscrap Logo.png';
import {Link} from "react-router-dom";

function Footer() {
  return (
    <div id="footer-id" className="FooterBox">

        <div className="FooterBoxDiv">
            <div className="FooterBoxRight">
                <div className="FooterLogo">
                    <img  className="mobileFooterLogo" src={logo} alt="" />
                </div>

                <div className="FooterBoxText1">
                    Reach out to us anytime and lets create a better future for all technology users together, forever. We are open to all types of collab offers and tons more.
                </div>
            </div>

            <div className="FooterLinks">
                <div className="Link">Links</div>
                <div className="LinksName">
                    <Link to="#"> About Us </Link><br/>
                    <Link to="#"> Services </Link><br/>
                    <Link to="#"> Why Choose us? </Link><br/>
                    <Link to="#"> Company Info </Link><br/>
                    <Link to="#"> Tech Stack </Link><br/>
                </div>
            </div>

            <div className="FooterLinks">
                <div className="Link">Contact us</div>
                <div className="LinksName" style={{width: '33rem'}}>
                    LOGSCRAP / (주)로그스크랩<br/><br/>
                    logscrap.kr@gmail.com<br/><br/>
                    26Floor, Convensia-daero 165, Yeonsu-gu, Incheon, Republic of Korea <br/> 인천광역시 연수구 컨벤시아대로 165, 26층
                </div>
            </div>


            <div className="FooterLinks2">
                <div className="LinksName">
                    <span>LOGSCRAP / (주)로그스크랩 logscrap.kr@gmail.com<br/><br/>
                    26Floor, Convensia-daero 165, Yeonsu-gu, Incheon, Republic of Korea <br/> 인천광역시 연수구 컨벤시아대로 165, 26층
                    </span>
                </div>
            </div>

        </div>

        <div id="footer_id" className="FooterContent">
            <div className="FooterContentText">
                © 2023 Copyright by LogScrap. All rights reserved.
            </div>
        </div>

    </div>
  );
}

export default Footer;
