import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

i18next.use(LanguageDetector).use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      translation: {
        "key": "Home",
        "Innovations": "Unleashing Innovations",
        "Across": "Across",
        "Technologies": "Technologies",
        "LOGSCRAP": "LOGSCRAP",
        "WeNot": "we're not just a technology company; we're architects of tomorrow's digital landscape. With a powerhouse of services and a team of top-tier IT experts, we bring your boldest ideas to life."
      }
    },
    ko: {
      translation: {
        "key": "Home",
        "Innovations": "Unleashing Innovations22222",
        "Across": "Across",
        "Technologies": "Technologies",
        "LOGSCRAP": "LOGSCRAP",
        "WeNot": "we're not just a technology company; we're architects of tomorrow's digital landscape. With a powerhouse of services and a team of top-tier IT experts, we bring your boldest ideas to life."
      }
    }
  }
});