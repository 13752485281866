import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Experts from "./Components/Experts";
import './i18n';
import ContactUs from "./Components/ContactUs";
import Slider from "./Components/Slider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<><Header /> <Home /> <Footer /></>}>
              {/*<Route path="/experts" element={<Experts />} />*/}
            </Route>

              <Route path="experts" element={<Experts />} />
              <Route path="contact_us" element={<ContactUs />} />
              <Route path="slider" element={<Slider />} />

          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
