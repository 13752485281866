import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

import mobileApp from "../images/Mobile_app_perspective_matte.png";
import codePerspective from "../images/Code_perspective_matte.png";
import dashboardIcon from "../images/Dashboard_perspective_matte.png";
import bigData from "../images/big_data.png";
import AI from "../images/AI.png";
import React, {useRef, useState, useEffect} from "react";
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper/modules";
import ArrowNext from "../mobile_images/arrow-next.svg";
import Cursor from "./cursor";

function Slider(){
    const swiperRef = useRef(null);

    const [nextClass, setNextClass] = useState('SliderButton');
    const [prevClass, setPrevClass] = useState('SliderButtonRound');
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [slidesPerGapView, setSlidesPerGagView] = useState(1);
    const [isDesktop, setisDesktop] = useState(true);

    useEffect(() => {
        if (window.innerWidth < 639){
            setSlidesPerView(2);
            setSlidesPerGagView("305vw");
            setisDesktop(false);
        }
        else {
            setSlidesPerView(4);
            setSlidesPerGagView(1);
            setisDesktop(true);
        }
    }, []);

   const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();

      setNextClass('SliderButtonRound');
      setPrevClass('SliderButton');

    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();

      setNextClass('SliderButton');
      setPrevClass('SliderButtonRound');

    }
  };

  const testing = () => {
    console.log("testing1");
  };


  return (
      <div id="our_core_offerings" className="Core">
          {isDesktop && (<Cursor/>)}

          <div className="SliderDiv">
                <div className="SliderDivContainer" >Our Core Offerings</div>
                <button className="MobileArrowNext2btn" onClick={goToPrevSlide} >
                    <img className="MobileArrowNext2" src={ArrowNext} alt="arrow-next" />
                </button>
            </div>
          <div className="swiper1" onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
      onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}>
            <Swiper
                  modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y,]}
                  spaceBetween={slidesPerGapView}
                  slidesPerView={slidesPerView}
                  navigation={true}
                  ref={swiperRef}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  loop={true}
                  speed={1000}
                  // autoplay={isDesktop ? undefined : { delay: 2000, disableOnInteraction: false }}
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                >

                <div className="swiper1">
                    <SwiperSlide key={0} >
                      <div className="SlidersClass">
                        <div className="SlidersClassDiv ">
                          <div className="SlidersClassDiv2">
                            <div className="SlidersClassDiv3" />
                            <img className="SlidersClassImg" src={mobileApp} alt="" />
                          </div>

                          <div className="SliderContent">
                              Mobile App Development
                          </div>

                            <div className="SliderText">A Website is an extension of yourself and we can help you to express it properly. Your website is your number one marketing asset because we live in a digital age.</div>

                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide key={1} >
                      <div className="SlidersClass">
                        <div className="SlidersClassDiv ">
                          <div className="SlidersClassDiv2">
                            <div className="SlidersClassDiv3" />
                            <img className="SlidersClassImg" src={bigData} alt="" />
                          </div>

                          <div className="SliderContent">
                              Big Data Analysis
                          </div>

                            <div className="SliderText">Transform data into actionable insights with our expert big data analysis. Navigate through vast datasets seamlessly, gaining the strategic advantage needed to make informed decisions and stay ahead in a data-driven world.</div>

                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide key={2}>
                            <div className="SlidersClass">
                                <div className="SlidersClassDiv">
                                  <div className="SlidersClassDiv2">
                                    <div className="SlidersClassDiv3" />
                                    <img className="SlidersClassImg" src={codePerspective} alt="" />
                                  </div>

                                  <div className="SliderContent">
                                      Software & Game Development
                                  </div>

                                    <div className="SliderText">Elevate your digital presence with our bespoke software and game development. From intuitive applications to immersive gaming experiences, we craft solutions that captivate audiences and drive engagement.</div>

                                </div>
                            </div>
                    </SwiperSlide>

                    <SwiperSlide key={3}>
                            <div className="SlidersClass">
                                <div className="SlidersClassDiv">
                                  <div className="SlidersClassDiv2">
                                    <div className="SlidersClassDiv3" />
                                    <img className="SlidersClassImg" src={dashboardIcon} alt="" />
                                  </div>

                                  <div className="SliderContent">
                                      Web Scraping
                                  </div>

                                    <div className="SliderText">Harness the power of the web with our precise web scraping services. Extracting valuable information from diverse sources, we provide you with real-time, accurate data to fuel your decision-making processes.</div>

                                </div>
                            </div>
                    </SwiperSlide>

                    <SwiperSlide key={4}>
                            <div className="SlidersClass">
                                <div className="SlidersClassDiv">
                                  <div className="SlidersClassDiv2">
                                    <div className="SlidersClassDiv3" />
                                    <img className="SlidersClassImg" src={AI} alt="" />
                                  </div>

                                  <div className="SliderContent">
                                      AI Solution Development
                                  </div>

                                    <div className="SliderText">Embrace the future with our cutting-edge AI solutions. Whether it's machine learning, natural language processing, or advanced analytics, we design solutions that not only meet but exceed the evolving demands of your business.</div>

                                </div>
                            </div>
                    </SwiperSlide>
                </div>


            </Swiper>

          </div>

           <div className="SliderButtonDiv">
                <button onClick={goToPrevSlide} className={nextClass}></button>
                <button onClick={goToNextSlide} className={prevClass}></button>
           </div>

      </div>
  );

}

export default Slider;